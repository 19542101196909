import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "My Journey to Learning Docker",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:54:21.000Z",
  "url": "/2018/02/my-journey-to-learning-docker/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I have been hearing about docker for quite a while, and it is on my `}{`“`}{`learn list`}{`”`}{` for quite a while.`}</p>
    <p>{`I have been using vagrant for quite some time, I have used it and ansible for configuring it and my production servers.`}</p>
    <p>{`Vagrant was good to me, but there were the times when I had several projects at a time and I needed an instance for mysql, mongo, php 7, php 5 and node. Those are a nice bunch of full pledged servers.`}</p>
    <p>{`My super here mac pro, handled it, but it had some lags from time to time. I heard that Docker is efficient and is super productive for development, production and by sharing with others.`}</p>
    <p>{`So after this long brief, I`}{`’`}{`ll start adding things I learn below, and in the end of my journey this would be a great cheetshit for docker.`}</p>
    <h5>{`Useful Notes: {#usefulnotes}`}</h5>
    <p><strong parentName="p">{`stop all containers:`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`docker stop $(docker ps -a -q)
`}</code></pre>
    <p><strong parentName="p">{`remove all containers:`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`docker rm $(docker ps -a -q)
`}</code></pre>
    <p><strong parentName="p">{`remove all images:`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`docker rmi $(docker images -q)
`}</code></pre>
    <p><strong parentName="p">{`run this folder inside nginx instance`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`docker run -v $(pwd):/usr/share/nginx/html -p 8080:80 nginx
`}</code></pre>
    <p>{`-v: volume, means get the host folder in to the docker container, in this example: $(pwd) means the current folder in to the nginx html folder.`}</p>
    <p>{`-p: maps ports between host and container, host 8080 points to container 80`}</p>
    <p>{`nginx `}{`–`}{` is the image name, in this example`}</p>
    <p><strong parentName="p">{`build a docker image`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`docker build -t YOUR_IMAGE_NAME .

//build from specific dockerfile
docker build -t my-php --file Dockerfile.php-fpm .
`}</code></pre>
    <p><strong parentName="p">{`connect to a container with `}{`“`}{`ssh`}{`”`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`docker run -it my-wordpress /bin/sh
`}</code></pre>
    <p><strong parentName="p">{`docker compose stuff`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`//build the images from the docker-compose.yml
docker-compose build

//run the docker-compose.yml
docker-compose build
`}</code></pre>
    <h6>{`Conclusion {#conclusion}`}</h6>
    <p>{`Let`}{`’`}{`s recap what we learned in this tutorial:`}</p>
    <ul>
      <li parentName="ul">{`Dockerfile is a blueprint for creating custom Docker images (or rather extending existing ones)`}</li>
      <li parentName="ul">{`Docker images are disposable, distributable and immutable filesystems`}</li>
      <li parentName="ul">{`Docker Containers provide basic Linux environment in which images can be ran`}</li>
      <li parentName="ul">{`Each project can define own Docker images based on the requirements`}</li>
      <li parentName="ul">{`With Docker, we don`}{`’`}{`t need to install anything locally (apart from Docker itself)`}</li>
      <li parentName="ul">{`For many purposes, we can just use existing images from Docker Hub`}</li>
      <li parentName="ul">{`Docker works natively on Linux with Windows and Mac support in the pipeline`}</li>
      <li parentName="ul">{`We can build images for anything, but each should have a single responsibility, usually in a form of a process running in the foreground`}</li>
      <li parentName="ul">{`docker-compose provides a convenient way to save us some typing`}</li>
      <li parentName="ul">{`Mapping provides a convenient way to connect host`}{`’`}{`s resources to the container`}</li>
      <li parentName="ul">{`Mounting provides a convenient way to plug in host`}{`’`}{`s directories in the container`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      